let config = {};

const prefix = 'REACT_APP_';

for (const [key, value] of Object.entries(process.env)) {
  if (!key.startsWith(prefix)) {
    continue;
  }
  const confKey = key.replace(prefix, '');
  config[confKey] = value;
}

config = {
  ...config,
  APP_VERSION: '1.19.3',
  APP_TITLE: 'Maverick Signings',
  // socket connection requests are sent to this backend path
  // must match backend value
  SOCKET_PATH: '/be/socket.io/',
  // socket client will connect to this namespace by default
  // must be a valid backend namespace
  SOCKET_NAMESPACE: 'cs',
  // api urls
  API_BE_URL: config.API_URL + '/cs',
  // number of seconds after which an inactive user is considered idle
  USER_IDLE_DELAY: 5,
  // max number of images uploaded in chat
  CHAT_MAX_IMAGES: 3,
  // max accepted size (in bytes) of images uploaded in chat
  CHAT_IMG_MAX_SIZE: 5242880, // = 5 MB
  // number of milliseconds after we manually check for mitek result
  FALLBACK_MITEK_RESULT_INTERVAL: 60000,
  // not used in app; for reference only
  MITEK_SDK_VERSION: '5.6.0',
};

export default config;