import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button } from "reactstrap";
import MetaTitle from "components/Shared/MetaTitle";
import { startAuto, startManual, clearMitekUI, stopSDK } from "services/mitek"
import { customMessagesHints } from "constants/mitekMessages"
import PreviewCapture from "./Steps/PreviewCapture";
import Preloader from "components/Shared/Preloader";
import socketEvent from "constants/socketEvent";
import { useSocket } from "context/socket";
import PropTypes from "prop-types";
import IdOptions from "./Steps/IdOptions";
import DriverLicenceInstructions from "./Steps/DriverLicenceInstructions";
import PassportInstructions from "./Steps/PassportInstructions";
import config from "config";
import CaptureTypeDialog from "./Partial/CaptureTypeDialog";
import { osName, osVersion, mobileModel, mobileVendor, browserName, browserVersion } from 'react-device-detect';
import { timestamp } from "helpers/dateHelper";
import CameraPermissionDenied from "./Partial/CameraPermissionDenied";

const Mitek = ({ upload, id, consentName, termsAccepted }) => {

  Mitek.propTypes = {
    upload: PropTypes.func,
    id: PropTypes.number,
    consentName: PropTypes.string,
    termsAccepted: PropTypes.bool,
  };

  const { socketEmit } = useSocket();

  /********** CONSTANTS **********/

  const DOC_TYPE_DL = "DRIVER_LICENSE";
  const DOC_TYPE_PASSPORT = "PASSPORT";

  const IMAGE_TYPE_DL_FRONT = 'DL_FRONT';
  const IMAGE_TYPE_DL_BACK = 'PDF417_BARCODE';
  const IMAGE_TYPE_PASSPORT = 'PASSPORT';
  const IMAGE_TYPE_SELFIE = 'SELFIE';

  const MODE_AUTO = 'AUTO';
  const MODE_MANUAL = 'MANUAL';

  const STEP_ID_OPTIONS = 3;
  const STEP_INSTRUCTIONS = 4;
  const STEP_CAPTURE = 1;
  const STEP_PREVIEW = 2;
  const STEP_COMPLETE = 5;

  /********** STATE **********/

  const [step, setStep] = useState(STEP_ID_OPTIONS);
  // captured images, in base64 format
  const [docFrontCapture, setDocFrontCapture] = useState('');
  const [docBackCapture, setDocBackCapture] = useState('');
  const [selfieCapture, setSelfieCapture] = useState('');
  // used for preview
  const [currentCapture, setCurrentCapture] = useState('');
  // error received when using manual mode
  const [error, setError] = useState('');
  const [isBusy, setIsBusy] = useState(false);
  // refers to the type of picture taken (IMAGE_TYPE_DL_FRONT, IMAGE_TYPE_SELFIE, etc)
  const [imageType, setImageType] = useState('');
  // refers to the type of id document used (driver license or passport)
  const [idDocType, setIdDocType] = useState('');
  // capture mode (auto/manual)
  const [mode, setMode] = useState(MODE_AUTO);
  const [showCaptureTypeDialog, setShowCaptureTypeDialog] = useState(false);
  const [showCameraPermissionDialog, setShowCameraPermissionDialog] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  // to emit the `vidProcessStarted` event
  useEffect(() => {
    emitStarted();
  }, []);

  // runs whenever the `step` changes
  useEffect(() => {
    switch (step) {
      case STEP_CAPTURE:
        takeCapture();
        break;
      case STEP_COMPLETE:
        verificationComplete();
    }
  }, [step]);

  // runs whenever the `mode` changes
  useEffect(() => {
    if (mode == MODE_MANUAL) {
      takeCapture();
    }
  }, [mode]);

  // runs whenever the `imageType` changes
  useEffect(() => {
    if (imageType) {
      // after each image type instructions, step forward
      setStep(STEP_CAPTURE)
    }
  }, [imageType]);

  /********** MITEK **********/

  const takeCapture = () => {
    if (mode == MODE_AUTO) {
      // Show loader only for auto capture, until camera starts
      setIsBusy(true);
      startAuto(imageType);
    } else {
      /* Don't show loader in manual mode and reset capture,
      in case user cancels operation and we have to start over */
      startManual(imageType);
      resetCaptureDetails();
    }
    $(document).one('mitek:media-image-selected', e => {
      // If user selected media file, show loader until we receive result
      setIsBusy(true);
    })
    $(document).one('mitek:camera-started', e => {
      setIsBusy(false);
    })
    $(document).one('mitek:image-captured', (e, captureData, error) => {
      // clear mitek sdk UI
      clearMitekUI();
      setCurrentCapture(captureData);
      setError(error);
      setStep(STEP_PREVIEW)
      setIsBusy(false);
    })
    $(document).one('mitek:force-stop', e => {
      setShowCaptureTypeDialog(true);
    })
    $(document).one('mitek:camera-permission-denied', e => {
      setIsBusy(false);
      setShowCameraPermissionDialog(true);
    })
  }

  const driverLicenseSelected = () => {
    mitekScienceSDK.cmd('COMPONENT_PRELOAD', {
      mitekSDKPath: "../../../mitekSDK",
      preloadComponents: [IMAGE_TYPE_DL_FRONT, IMAGE_TYPE_DL_BACK, IMAGE_TYPE_SELFIE],
      options: {
        license: config.MITEK_LICENSE
      }
    });
    setIdDocType(DOC_TYPE_DL);
    setStep(STEP_INSTRUCTIONS);
  }

  const passportSelected = () => {
    mitekScienceSDK.cmd('COMPONENT_PRELOAD', {
      mitekSDKPath: "../../../mitekSDK",
      preloadComponents: [IMAGE_TYPE_PASSPORT, IMAGE_TYPE_SELFIE],
      options: {
        license: config.MITEK_LICENSE
      }
    });
    setIdDocType(DOC_TYPE_PASSPORT)
    setStep(STEP_INSTRUCTIONS);
  }

  const captureDocument = (docType) => {
    if (docType == imageType) {
      /* if selected doc type is the same as previous,
      trigger capture manually, because useEffect will not be triggered
      */
      setStep(STEP_CAPTURE);
    }
    setImageType(docType)
  }

  const acceptCapture = () => {
    switch (imageType) {
      case IMAGE_TYPE_DL_FRONT:
      case IMAGE_TYPE_PASSPORT:
        setDocFrontCapture(currentCapture);
        break;
      case IMAGE_TYPE_DL_BACK:
        setDocBackCapture(currentCapture);
        break;
      case IMAGE_TYPE_SELFIE:
        setSelfieCapture(currentCapture);
        break;
    }
    resetCaptureDetails();
  }

  const selectManualMode = () => {
    setShowCaptureTypeDialog(false)
    setMode(MODE_MANUAL);
  }

  const selectAutoMode = () => {
    setShowCaptureTypeDialog(false)
    // the mode and image type is already set
    // we can take capture right away
    takeCapture();
  }

  const clearMitek = () => {
    stopSDK();
    resetCaptureDetails();
  }

  const resetCaptureDetails = () => {
    // go back to instructions page
    setStep(STEP_INSTRUCTIONS);
    // reset mode
    setMode(MODE_AUTO);
  }

  const retry = () => {
    setStep(STEP_CAPTURE);
  }

  const back = () => {
    setStep(STEP_ID_OPTIONS);
    setIdDocType('');
    setImageType('');
    setDocFrontCapture('');
    setDocBackCapture('');
    setSelfieCapture('');
  }

  /********** BACKEND CALLS **********/

  const verificationComplete = () => {
    const payload = {
      termsAccepted: termsAccepted,
      consentName: consentName,
      IDType: idDocType,
      docFrontCapture: docFrontCapture.replace('data:image/jpeg;base64,', ''),
      docBackCapture: docBackCapture.replace('data:image/jpeg;base64,', ''),
      selfieCapture: selfieCapture.replace('data:image/jpeg;base64,', ''),
      userAgent: {
        captureTime: timestamp(),
        osName: osName,
        osVersion: osVersion,
        device: `${mobileVendor} ${mobileModel}`,
        browserName: browserName,
        browserVersion: browserVersion,
      }
    }
    upload(payload);
  }

  /********** SOCKET EVENTS **********/

  // Notify desktop that id verification started
  const emitStarted = () => {
    socketEmit(socketEvent.vidProcessStarted, { id: id })
  }

  /*********** DIALOGS *************/

  const exitCaptureTypeDialog = () => {
    setShowCaptureTypeDialog(false)
    resetCaptureDetails();
  }

  const exitCameraPermissionDialog = () => {
    setShowCameraPermissionDialog(false)
    resetCaptureDetails();
  }

  return <React.Fragment>
    <MetaTitle>ID Validation</MetaTitle>
    <div className="account-pages">
      <Container>
        {showCaptureTypeDialog && <CaptureTypeDialog retryAutocapture={selectAutoMode} switchToManual={selectManualMode} exitDialog={exitCaptureTypeDialog} />}
        {showCameraPermissionDialog && <CameraPermissionDenied exitDialog={exitCameraPermissionDialog} />}
        <div id="captureHeader" className="d-none picture-header">
          <div id="headerText">{customMessagesHints[imageType]}</div>
          <Button id="closeCaptureBtn" onClick={clearMitek}>
            <i className="fas fa-times"></i>
          </Button>
        </div>
        <Row className="justify-content-center">
          <Col md={9} lg={6} xl={5} className="full-height-column p-0">
            <div className="overflow-hidden" id="vid_mobile_main_card">
              {step == STEP_ID_OPTIONS && <IdOptions driverLicenseSelected={driverLicenseSelected} passportSelected={passportSelected} />}
              {step == STEP_INSTRUCTIONS && idDocType == DOC_TYPE_DL && <DriverLicenceInstructions frontSrc={docFrontCapture} backSrc={docBackCapture} selfieSrc={selfieCapture} startCapture={captureDocument} back={back} complete={verificationComplete} />}
              {step == STEP_INSTRUCTIONS && idDocType == DOC_TYPE_PASSPORT && <PassportInstructions frontSrc={docFrontCapture} selfieSrc={selfieCapture} startCapture={captureDocument} back={back} complete={verificationComplete} />}
              {step == STEP_PREVIEW && <PreviewCapture previewImage={currentCapture} error={error} retry={retry} cancel={resetCaptureDetails} accept={acceptCapture} imageType={imageType} />}
            </div>
          </Col>
        </Row>
        {isBusy && <Preloader />}
      </Container>
    </div>
  </React.Fragment>
}

export default Mitek;
