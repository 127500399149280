import React from "react";
import { Col, Button } from "reactstrap";
import iconSrc from 'assets/images/desktop/pending_complete_icon.png';
import progressSrc from 'assets/images/desktop/progress-bar-complete.png';
import { Link } from "react-router-dom";
import { useNavigator } from "context/navigator";

const VidSuccess = () => {

  // navigator context that helps determine the next available route
  const { getNextRoute } = useNavigator();

  return <React.Fragment>
    <div id="vid_success_parent" className="vid-progress">
      <Col sm={7} md={7} xl={7} className="text-center m-auto pt-5">
        <img id="vid_icon" src={iconSrc}></img>
        <h5 id="vid_title" className="mt-5">ID Verification Successful</h5>
        <img id="progress_bar" src={progressSrc}></img>
        <div className="vid-desc">Your documents have been reviewed</div>
        <div className="vid-desc">ID verification was successful.</div>
        <div className="vid-desc">You may continue now.</div>
        <Link to={getNextRoute()}><Button className="main-btn" >Continue</Button></Link>
      </Col>
    </div>
  </React.Fragment>
}

export default VidSuccess;