import React from "react";
import { CardBody } from "reactstrap";
import PropTypes from "prop-types";
import { previewRetakeHints } from "constants/mitekMessages";

const PreviewCapture = (props) => {

  PreviewCapture.propTypes = {
    previewImage: PropTypes.string,
    cancel: PropTypes.func,
    retry: PropTypes.func,
    accept: PropTypes.func,
    imageType: PropTypes.string,
    error: PropTypes.string,
  };

  return <React.Fragment>
    <CardBody id="previewCaptureCard">
      <div className="picture-header">
        <div id="headerText">
          {props.error || "Check the quality of the image. " + previewRetakeHints[props.imageType]}</div>
      </div>
      <img id="previewImage" src={props.previewImage} />
      <div id="previewFooter">
        <div id="previewActionsRow" className="mt-2">
          <div className="preview-action">
            <button type="button" onClick={props.cancel}>
              <i className="fas fa-times"></i>
            </button>
            <label>Cancel</label>
          </div>
          <div className="preview-action">
            {!props.error && <>
              <button type="button" className="primary scan-btn-mobile" onClick={props.accept}>
                <i className="fas fa-check"></i>
              </button>
              <label>keep it!</label>
            </>
            }
          </div>
          <div className="preview-action">
            <button type="button" onClick={props.retry}>
              <i className="fas fa-undo fa-sm"></i>
            </button>
            <label>Retry</label>
          </div>
        </div>
        <div className="powered">Powered by Mavsign</div>
      </div>
    </CardBody>
  </React.Fragment>
}

export default PreviewCapture;
