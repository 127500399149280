import React from "react";
import { Card, CardBody, Row, Col, Container } from "reactstrap";
import iconSrc from 'assets/images/desktop/pending_complete_icon.png';
import progressSrc from 'assets/images/desktop/progress-bar-complete.png';
import { Link } from "react-router-dom";
import { useNavigator } from "context/navigator";
import vidSrc from "assets/images/vid1.png";

const VidSuccess = () => {

  // navigator context that helps determine the next available route
  const { getNextRoute } = useNavigator();

  return <React.Fragment>
    <Container>
      <div className="header-image-box">
        <img className="header-image" src={vidSrc} />
      </div>
      <Row className="justify-content-center">
        <Col md={8} lg={6} xl={5} className="vid-column mt-0">
          <Card>
            <CardBody>
              <div className="pt-2 text-center">
                <img className="mt-3" src={iconSrc}></img>
                <h5 className="mt-4 mb-4 title-card-mobile">ID Verification Successful</h5>
                <img src={progressSrc}></img>
                <div className="mt-4 card-description">Your documents have been reviewed.</div>
                <div className="card-description">ID verification was successful.</div>
                <div className="card-description">You may continue now.</div>
              </div>
              <Link to={getNextRoute()} className="btn btn-primary scan-btn-mobile w-100 mt-4 mb-1">Continue</Link>
            </CardBody>
          </Card>
          <Card>
          </Card>
        </Col>
      </Row>
    </Container>
  </React.Fragment>
}

export default VidSuccess;
