import React from "react";
import { Row, CardBody, Col, Card } from "reactstrap";
import PropTypes from "prop-types";
import vidSrc from "assets/images/vid1.png";
import VidPending from '../../../DesktopContent/Steps/VidPending';
import info from 'assets/images/info.svg';
import id from 'assets/images/ID.svg';
import wifi from 'assets/images/wifi.svg';
import light from 'assets/images/light.svg';

const IdOptions = ({ driverLicenseSelected, passportSelected }) => {

  IdOptions.propTypes = {
    driverLicenseSelected: PropTypes.func,
    passportSelected: PropTypes.func,
  };

  return <React.Fragment>
    <div id="id_options" className="account-pages mb-3">
      <div className="header-image-box">
        <img className="header-image" src={vidSrc} />
      </div>
      <div className="justify-content-center">
        <Col className="vid-column">
          <Card>
            <CardBody className="pt-3">
              <div className="p-2 mb-2">
                <h5 className="confirm-title text-center">Scan your driver license/passport and take a selfie to confirm your identity</h5>
              </div>
              <div className="d-flex fade show alert-box">
                <img src={info} className="mx-2" />
                <span className="my-2 sure-text">Temporary IDs or paper IDs are not valid</span>
              </div>
              <div className="mb-3 mt-4 alert-text">Make sure:</div>
              <div className="mb-3 alert-box-sure d-flex">
                <img src={id} className="mx-2" />
                <span className="text">You have your Driver License/Passport</span>
              </div>
              <div className="mb-3 alert-box-sure d-flex">
                <img src={id} className="mx-2" />
                <span className="text">For Passport Cards go to Driver License tab</span>
              </div>
              <div className="mb-3 alert-box-sure d-flex">
                <img src={wifi} className="mx-2" />
                <span className="text">You have good internet connection</span>
              </div>
              <div className="mb-3 alert-box-sure d-flex">
                <img src={light} className="mx-2" />
                <span className="text">You are in a well-lit space</span>
              </div>
            </CardBody>
          </Card>
          <Row className="bottom-row">
            <Col>
              <button className="btn btn-primary w-100 start-btn-mobile" onClick={driverLicenseSelected}>Use Driver License</button>
            </Col>
            <Col>
              <button className="btn btn-primary w-100 start-btn-mobile" onClick={passportSelected}>Use Passport</button>
            </Col>
          </Row>
        </Col>
      </div>
    </div>
  </React.Fragment>

}

export default IdOptions;