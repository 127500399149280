import React from "react";
import vidSrc from "assets/images/vid1.png";
import ValidationNav from "../Partial/ValidationNav";

const DesktopHeader = () => {

  return <React.Fragment>
    <div id="vid_desktop_header" className="d-flex justify-content-center">
      <img id="header_image" src={vidSrc} />
    </div>
  </React.Fragment>
}

export default DesktopHeader;