import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, Container } from "reactstrap";
import MobileMenu from "components/Shared/MobileMenu";
import Preloader from "components/Shared/Preloader";
import NotFound from "pages/Error/NotFound";
import { useAppointment } from "context/appointment";
import ActiveStep from "./ActiveStep";
import OrderNotary from "model/orderNotary";
import AppointmentDeclined from "./Partial/AppointmentDeclined";
import { Link } from "react-router-dom";
import { route } from "helpers/routeHelper";
import { getOrderESignContracts } from "helpers/backendHelper";
import OrderDoc from "model/orderDoc";
import menuStepsMobile from "constants/menuStepsMobile";
import appointmentSrc from "assets/images/appointment/appointment-banner.png";

const MobileContent = () => {

  /********** STATE **********/

  const { order, isBusy, orderError } = useAppointment();

  const [isLoadDocsBusy, setIsLoadDocsBusy] = useState(false);

  // user can move forward if there are esign docs left to sign
  const [canMoveForward, setCanMoveForward] = useState(false);
  const isSetAppointmentStep = order?.orderNotary?.status === OrderNotary.STATUS_APPOINTMENT_SET;
  const isAppointmentAcceptedStep = order?.orderNotary?.status === OrderNotary.STATUS_APPOINTMENT_ACCEPTED;
  const canMoveBack = isAppointmentAcceptedStep ? (order?.isEsignRequired && canMoveForward) : true;

  /********** EFFECTS **********/

  // runs whenever `order` changes
  // including at component mount
  useEffect(() => {
    if (!order) return;
    // check if order has e-sign required
    // we need to know if we must continue with e-sign or finish
    if (order.isEsignRequired) {
      setIsLoadDocsBusy(true);
      getOrderESignContracts().then(res => {
        setIsLoadDocsBusy(false);
        if (!res.docs?.length || !res.docs.find(doc => doc.status > OrderDoc.STATUS_PENDING_SIGNATURES_PLACEMENT)) {
          // error fetching documents
          // or no doc has been uploaded
          setCanMoveForward(false);
          return;
        }
        let allSubmitted = true;
        res.docs.forEach(doc => {
          if (doc.signingId == null) {
            allSubmitted = false;
          }
        })
        if (allSubmitted) {
          setCanMoveForward(false);
        } else {
          setCanMoveForward(true);
        }
      }).catch(err => {
        // error fetching documents
        setIsLoadDocsBusy(false);
        setCanMoveForward(false);
      })
    }
  }, [order]);

  return <React.Fragment>
    {!!order && <React.Fragment>
      {/* if the notary appointment was set and accepted, we consider the customer flow to be finished, since everything is completed from their side */}
      <MobileMenu activeStep={order.orderNotary?.status >= OrderNotary.STATUS_APPOINTMENT_DECLINED ? menuStepsMobile.FINISH : menuStepsMobile.SIGNATURES} itemWidth={menuStepsMobile.FINISH ? "50%" : "100%"} />
      <Container className="px-0 overflow-hidden">
        <Row className="justify-content-center">
          <div className="justify-content-center d-flex">
            <img className="header-image-appointment-mobile" src={appointmentSrc} />
          </div>
          <Col md={8} lg={6} xl={5} className="pt-4">
            {order.orderNotary?.status === OrderNotary.STATUS_APPOINTMENT_DECLINED ? <AppointmentDeclined /> : (
              <Card className="card-parent-mobile">
                <CardBody>
                  <ActiveStep />
                  {!isSetAppointmentStep && (
                    <div className="btns-wrapper mt-4">
                      {canMoveForward && <Link id="continueBtn" className="btn btn-primary" to={route("e_signature")}>Continue</Link>}
                    </div>
                  )}
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>}
    {(isBusy || isLoadDocsBusy) && <Preloader />}
    {orderError && <NotFound />}
  </React.Fragment>
}

export default MobileContent;
