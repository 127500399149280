import React from "react";
// import appointmentSrc from "assets/images/appointment/appointment-banner.png";
import iconSrc from "assets/images/desktop/pending-img.svg";

const AppointmentPending = () => {
  return <div>
    <div className="text-center pb-4 appointment-result">
      <img className="pending-icon" src={iconSrc}></img>
      <h5 className="mt-4 appointment-card-title">Pending Appointment</h5>
      <div className="mt-2 pending-appointment-paragraph mt-4 mb-4">A notary will be in contact to set a signing appointment within 24 hours after the documents are delivered to the notary. Thank you!</div>
      <p className="mb-4 footer-box-text">You can close the tab now.</p>
    </div>
  </div>
};

export default AppointmentPending;