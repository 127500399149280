import React, { useState } from "react";
import { Col } from "reactstrap";
import iconSrc from 'assets/images/desktop/identity-validation-complete.png';
import progressSrc from 'assets/images/desktop/progress-bar.png';

const VidComplete = () => {

  const [refreshInProgress, setRefreshInProgress] = useState(false);

  // sometimes, even if the VID request passed, a socket event update may not be received
  // ss a work-around, we enable the user to refresh the VID request result manually
  const manualCheck = () => {
    setRefreshInProgress(true);
    window.location.reload();
  }

  return <React.Fragment>
    <div id="vid_complete_parent" className="vid-progress">
      <Col sm={7} md={7} xl={7} className="text-center m-auto pt-5">
        <img id="vid_icon" src={iconSrc}></img>
        <h5 id="vid_title" className="mt-5"><b>Identity validation completed</b></h5>
        <img id="progress_bar" src={progressSrc}></img>
        <div className="vid-desc">The agent is reviewing the submited documents</div>
        <button className={"btn btn-primary w-100 mt-5  submit-btn-desktop" + (refreshInProgress ? ' disabled' : '')} onClick={manualCheck}>Check status</button>
      </Col>
    </div>
  </React.Fragment>
}

export default VidComplete;