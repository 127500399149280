import React from "react";
import { Card, CardBody, Row, Col, Container } from "reactstrap";
import iconSrc from 'assets/images/desktop/vid-error-icon.png';
import progressSrc from 'assets/images/desktop/progress-bar-complete.png';
import PropTypes from "prop-types";
import vidSrc from "assets/images/vid1.png";

const VidMitekApiFail = (props) => {

  return <React.Fragment>
    <Container>
      <div className="header-image-box">
        <img className="header-image" src={vidSrc} />
      </div>
      <Row className="justify-content-center">
        <Col md={8} lg={6} xl={5} className="vid-column mt-0">
          <Card>
            <CardBody>
              <div className="pt-2 text-center">
                <img className="mt-3" src={iconSrc}></img>
                <h5 className="mt-3 mb-4 title-card-mobile">Error!</h5>
                <img src={progressSrc}></img>
                <div className="mt-4 card-description">Identity Verification Incomplete</div>
                <div className="card-description">An error ocurred, please try again.</div>
              </div>
              <button className="btn btn-primary scan-btn-mobile w-100 mt-4 mb-1" onClick={props.tryAgain}>Try again</button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </React.Fragment>
}

VidMitekApiFail.propTypes = {
  tryAgain: PropTypes.func,
};

export default VidMitekApiFail;
