import React, { useEffect, useState, useCallback } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import Capture from "./Capture";
import ContractItem from "../Partial/ContractItem";
import { getOrderDoc, getOrderESignContracts, getOrderInkSignContracts, uploadContractPage, uploadOrderDocPage, getOrderShipping } from "helpers/backendHelper";
import Preloader from "components/Shared/Preloader";
import NotFound from "pages/Error/NotFound";
import { Link } from "react-router-dom";
import { useSocketOn } from "hooks/socket";
import socketEvent from "constants/socketEvent";
import OrderDoc from "model/orderDoc";
import { route } from "helpers/routeHelper";
import PropTypes from "prop-types";
import { showError } from "helpers/utilHelper";
import TrackingModal from "components/Shared/TrackingModal";
import { formatDate, formats } from "helpers/dateHelper";
import Order from "model/order";

// receives shipping data and transforms it into an array of objects containing date, description and address
const normalizeTrackingData = (shippingCompany, data) => {
  if (shippingCompany === Order.SHIPPING_COMPANY_FEDEX) {
    const events = data.map(event => {
      const address = [];
      const { city, stateOrProvinceCode, postalCode } = event.scanLocation;
      // build the address based on the available information
      // since the response may not contain all the address details
      if (city) address.push(city);
      if (stateOrProvinceCode) address.push(stateOrProvinceCode);
      if (postalCode) address.push(postalCode);
      // format the date that is of type ISO string
      const date = formatDate(event.date, formats.TRACKING_DATE);
      return {
        date,
        description: event.eventDescription,
        address,
      }
    })
    return events;
  }
  if (shippingCompany === Order.SHIPPING_COMPANY_UPS) {
    const events = data.map(event => {
      const address = [];
      const { city, stateProvince, postalCode } = event.location?.address || {};
      // build the address based on the available information
      // since the response may not contain all the address details
      if (city) address.push(city);
      if (stateProvince) address.push(stateProvince);
      if (postalCode) address.push(postalCode);
      // format the date that is of type YYYYMMDD
      const date = formatDate(event.date, formats.TRACKING_DATE);
      return {
        date,
        description: event.status?.description,
        address,
      }
    })
    return events;
  }
  return data;
}

const Contracts = ({ hasESign, order }) => {
  // current contract and page selected for scanning
  // used object instead of separate values, to trigger capture when both details are set
  const [captureDetails, setCaptureDetails] = useState({ contractIdx: null, pageId: null });
  // toggle capture mode on/off
  const [takeCapture, setTakeCapture] = useState(false);
  // stores all captures for each contract
  const [contractCaptures, setContractCaptures] = useState([]);
  // stores open/closed state for each tab
  const [tabsState, setTabsState] = useState([]);
  const [isBusy, setIsBusy] = useState(true);
  const [contracts, setContracts] = useState(null);
  const [contractsError, setContractsError] = useState(false);
  // check whether we can continue or finish
  const [isInkSignComplete, setIsInkSignComplete] = useState(false);
  const [canMoveForward, setCanMoveForward] = useState(false);
  const [moveForwardRoute, setMoveForwardRoute] = useState("finish");
  const [isShippingModalOpen, setIsShippingModalOpen] = useState(false);
  // Data for modals
  const [shippingData, setShippingData] = useState(null);
  const [shippingDataError, setShippingDataError] = useState(false);

  useEffect(() => {
    getOrderInkSignContracts().then(res => {
      setIsBusy(false);
      if (res.docs) {
        // set default state for documents, captures and tabs state
        let capturesDefault = [];
        let tabsStateDefault = [];
        let docs = [];
        res.docs.forEach((contract, index) => {
          // contract is refered to by index, when performing captures
          // this helps keeping contracts sorted
          contract.index = index;
          // order uuid needed for page captures retrieval
          contract.orderSuuid = res.orderSuuid;
          capturesDefault[contract.id] = [];
          tabsStateDefault[contract.id] = false;
          docs[index] = contract;
        })
        setContractCaptures(capturesDefault);
        setTabsState(tabsStateDefault);
        setContracts(docs);
      } else {
        // error fetching documents
        setContractsError(true);
        return;
      }
    }).catch(err => {
      // error fetching documents
      setContractsError(true);
      setIsBusy(false);
    })
    // check if order has e-sign required
    // we need to know if we must continue with e-sign or finish
    if (!hasESign) {
      setCanMoveForward(true);
      return;
    }
    setMoveForwardRoute("e_signature");
    getOrderESignContracts().then(res => {
      // if there is no e-sign doc ready to sign yet, user shouldn't move forward
      if (!res.docs?.length || !res.docs.find(doc => doc.status > OrderDoc.STATUS_PENDING_SIGNATURES_PLACEMENT)) {
        return;
      }
      let allSubmitted = true;
      res.docs.forEach(doc => {
        if (doc.signingId == null) {
          allSubmitted = false;
        }
      })
      if (allSubmitted) {
        setMoveForwardRoute("finish");
      }
      setCanMoveForward(true);
    }).catch(err => {
      // error fetching documents
    })
  }, []);

  // runs whenever `contracts` changes
  // to make verifications in order to determine if we are able to continue with ink-sign or finish
  useEffect(() => {
    if (!contracts?.length) return
    let localCanMove = true;
    // if there are still some ink contracts to sign, don't allow the user to move to the next step
    contracts.forEach(contract => {
      if (contract.status < OrderDoc.STATUS_PENDING_DEALER_REVIEW || contract.status == OrderDoc.STATUS_REJECTED) {
        localCanMove = false;
      }
    })
    // otherwise, allow the user to move to the next step
    setIsInkSignComplete(localCanMove);
  }, [contracts]);

  // Shipping
  useEffect(() => {
    if (order?.shippingPackageAwb) {
      getShippingData();
    }
  }, [order?.shippingPackageAwb]);

  const toggleShippingModal = () => setIsShippingModalOpen(!isShippingModalOpen);

  const getShippingData = () => {
    getOrderShipping(order.id)
      .then(response => {
        // the shipping response object is different for UPS/Fedex, we need to bring it to a standard form
        const normalizedData = normalizeTrackingData(order.shippingCompany, response.response.events);
        setShippingData(normalizedData);
      })
      .catch(err => setShippingDataError(true));
  }

  // runs whenever `captureDetails` change
  // in order to flag that the user has taken a capture
  useEffect(() => {
    // both contractIndex and pageID values must be set, in order to perform capture
    if (captureDetails.contractIdx != null && captureDetails.pageId != null) {
      setTakeCapture(true);
    }
  }, [captureDetails]);

  // get image as base64 from capture session
  const getCapture = (image) => {
    let scannedContractIdx = captureDetails.contractIdx;
    let scannedPageId = captureDetails.pageId;
    let updatedCaptures = contractCaptures;
    if (!updatedCaptures[scannedContractIdx]) {
      updatedCaptures[scannedContractIdx] = [];
    }
    updatedCaptures[scannedContractIdx][scannedPageId] = image;
    setContractCaptures(updatedCaptures)
    upload(image);
  }

  // set the values of current contract and page to be scanned
  const scanPage = (contractIdx, pageId) => {
    setCaptureDetails({ contractIdx, pageId })
  }

  // go to next contract page
  const increasePageNumber = () => {
    let oldDetails = captureDetails;
    const toBeScanedPages = getToBeScannedPages();
    let nextPageId = null;
    toBeScanedPages.forEach(pageId => {
      if (!nextPageId && pageId > oldDetails.pageId) {
        // find the next page that needs scanning
        nextPageId = pageId;
      }
    })
    if (!nextPageId && toBeScanedPages.length) {
      // we did not find a following page that needs scanning, so take the first one
      nextPageId = toBeScanedPages[0];
    }
    if (nextPageId) {
      oldDetails.pageId = parseInt(nextPageId);
      setCaptureDetails({ ...oldDetails });
    }
  }

  const getToBeScannedPages = () => {
    let oldDetails = captureDetails;
    const toBeScanedPages = [];
    const contract = contracts[captureDetails.contractIdx];
    for (let index = 1; index <= contract.numOfPages; index++) {
      if (index == oldDetails.pageId) {
        // don't process the page we just captured
        continue;
      }
      if (contract.pages && contract.pages[index] != null) {
        if (contract.pages[index].status == OrderDoc.PAGE_STATUS_REJECTED) {
          // rejected page
          toBeScanedPages.push(index);
        }
      } else {
        // not yet scanned page
        toBeScanedPages.push(index);
      }
    }
    return toBeScanedPages;
  }

  // when closing capture component, and returning to contracts list
  // make sure that open/closed state is preserved for each tab
  const setTabState = (state, contractId) => {
    let oldTabs = tabsState;
    oldTabs[contractId] = state;
    setTabsState({ ...oldTabs })
  }

  // when photo captured, send it to api
  const upload = (image) => {
    image = image.replace(/data:image\/[^;]+;base64,/, '');
    uploadContractPage({ pageImg: image }, captureDetails.pageId, contracts[captureDetails.contractIdx].id);
  }

  const uploadBinaryImage = async (docId, pageNum, image) => {
    const formData = new FormData();
    formData.append("pageImg", image);
    try {
      await uploadOrderDocPage(formData, docId, pageNum);
    } catch (err) {
      showError("Unable to upload page");
    } finally {

    }
  }

  /********** EVENT LISTENERS ********/

  const onOrderDocChanged = useCallback(({ id }) => {
    getOrderDoc(id).then(res => {
      if (!res.orderDoc) {
        return;
      }
      setIsBusy(true);
      const orderDoc = res.orderDoc;
      let oldDocs = [...contracts];
      const index = oldDocs.findIndex(elem => elem.id == orderDoc.id);
      // set the new doc status, and pages statuses
      oldDocs[index].status = orderDoc.status;
      oldDocs[index].pages = orderDoc.pages;
      setContracts(oldDocs);
      setIsBusy(false);
    }).catch(err => {
      // if socket fails, don't show error.
      // page refresh will fix the issue
    })
  }, [contracts]);

  // listen for the order doc changed event
  useSocketOn(socketEvent.orderDocChanged, onOrderDocChanged);

  return <React.Fragment>
    {contracts && <>
      {takeCapture &&
        <Capture getCapture={getCapture} contract={contracts[captureDetails.contractIdx]} pageId={captureDetails.pageId}
          back={() => setTakeCapture(false)} nextPage={increasePageNumber} getToBeScannedPages={getToBeScannedPages} />
      }
      <Card id="contracts_card" className={takeCapture ? 'd-none' : ''}>
        <CardBody id="contracts_card_body">
          {order.docDeliveryOption == Order.DOC_DELIVERY_OPTION_SHIPPING &&
            <div className="tracking-number-parent">
              <div className="tracking-text">Tracking number</div>
              <div className="text-center">
                {order?.shippingPackageAwb ? <span className='tracking-link cursor-pointer' onClick={() => setIsShippingModalOpen(true)}>
                  {order?.shippingPackageAwb}</span> : '--'}
              </div>
            </div>
          }
          <div className="accordion" id="accordion">
            {contracts.length ? contracts.map((contract, index) => (
              <ContractItem contract={contract} key={index} scanPage={scanPage} captures={contractCaptures} getState={setTabState} tabState={tabsState[contract.id]} uploadHandler={uploadBinaryImage} />
            ))
              : <div className="no-contracts-message">No contracts added</div>
            }
          </div>
          <div className="continue-btn-wrapper">
            <Row className="justify-content-center">
              <Col md={9} lg={6} xl={5}>
                <Link className={'btn btn-primary w-100 ' + (!isInkSignComplete || !canMoveForward ? 'disabled' : '')} to={route(moveForwardRoute)}>Continue</Link>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </>
    }
    {/* Shipping Modal */}
    <TrackingModal
      isOpen={isShippingModalOpen}
      toggle={toggleShippingModal}
      modalData={shippingData}
      modalDataError={shippingDataError}
      closeModal={() => setIsShippingModalOpen(false)}
      trackingNumber={order?.shippingPackageAwb}
    />
    {isBusy && <Preloader />}
    {contractsError && <NotFound />}
  </React.Fragment>
}

Contracts.propTypes = {
  hasESign: PropTypes.number,
  order: PropTypes.object,
};

export default Contracts;
