import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, Container } from "reactstrap";
import iconSrc from 'assets/images/desktop/finish-screen-icon.svg';
import progressSrc from 'assets/images/desktop/progress-bar-complete.png';
import MobileMenu from "components/Shared/MobileMenu";
import { useAuth } from "context/auth";
import { getOrder } from "helpers/backendHelper";
import { getBeUrl } from "helpers/utilHelper";
import { addAccessToken } from "helpers/tokenHelper";
import NotFound from "pages/Error/NotFound";
import MailBox from "assets/images/desktop/markunread_mailbox.png";
import textSnippet from "assets/images/desktop/text_snippet.png";
import menuStepsMobile from "constants/menuStepsMobile";
import OrderSigner from 'model/orderSigner';
import finishSrc from "assets/images/desktop/finish-screen-banner.png";

const MobileContent = () => {

  const { user: authUser } = useAuth();

  const [order, setOrder] = useState(null);
  const [orderError, setOrderError] = useState(false);

  // runs once on component mount
  useEffect(() => {
    getOrder().then(res => {
      if (!res.order) {
        setOrderError(true);
        return;
      }
      setOrder(res.order)
    }).catch(err => {
      setOrderError(true);
    })
  }, []);

  return <React.Fragment>
    <MobileMenu activeStep={menuStepsMobile.FINISH} itemWidth="100%" />
    <Container className="px-0">
      <img className="header-image" src={finishSrc} />
      <Row className="justify-content-center">
        <Col md={8} lg={6} xl={5} className="finish-column">
          <Card>
            <CardBody>
              <div className="pt-2 text-center">
                <img className="mt-3 verif-icon" src={iconSrc}></img>
                <h5 className="mt-5 mb-4 title-card-mobile">Thank you, {authUser.fullName}!</h5>
                <img src={progressSrc}></img>
                <p className="finish-message mb-2 card-description mt-4">All your documents have been submitted successfully.</p>
                {authUser.type === OrderSigner.TYPE_CUSTOMER &&
                  <p className="finish-message card-description">Your dealership representative will be in touch with you shortly. </p>
                }
                <p className="finish-message card-description">You can leave this page now.</p>
                {!!order && !!order.returnLabel && <div className="d-flex flex-column">
                  <div className="divider"></div>
                  <div className="finish-message d-flex">
                    <div>
                      <img src={MailBox} alt="" className="me-2" />
                    </div>
                    <div className="return-label-mobile card-description">A Return Envelope will be provided with the documents. If no return envelope is included, your Maverick scheduler will contact the dealership for the return label.</div>
                  </div>
                  <div className="divider"></div>
                  <div className="finish-message d-flex">
                    <div>
                      <img src={textSnippet} alt="" className="me-2" />
                    </div>
                    <div className="return-label-mobile card-description">Make sure you include all the original signed documents to the envelope and seal it properly. Go to your nearest FedEx/UPS as soon as possible.</div>
                  </div>
                </div>}
              </div>
            </CardBody>
          </Card>
          {!!order && !!order.returnLabel && <>
            <div className="text-center">
              <a href={getBeUrl(addAccessToken(`order/return-label/download`))} className="btn btn-primary w-100">
                <div className="d-flex justify-content-center align-items-center">
                  <span className="fw-medium text-start card-description pe-2">Download Return Label</span>
                  <i className="bx bx-download font-size-20" />
                </div>
              </a>
            </div>
          </>}
        </Col>
      </Row>
    </Container>
    {orderError && <NotFound />}
  </React.Fragment>
}

export default MobileContent;
