import React from "react";
import { Col } from "reactstrap";
import iconSrc from 'assets/images/desktop/pending-img-mobile.svg';
import progressSrc from 'assets/images/desktop/progress-bar.png';

const VidPending = () => {

  return <React.Fragment>
    <div id="vid_pending_parent" className="vid-progress">
      <Col sm={7} md={7} xl={7} className="text-center m-auto pt-5">
        <img id="vid_icon" src={iconSrc}></img>
        <h5 id="vid_title" className="mt-4 mb-3">Pending verification</h5>
        <img id="progress_bar" src={progressSrc}></img>
        <div className="vid-desc mt-3 mb-4">Please complete de ID validation on your mobile device to be able to move to the next step.</div>
      </Col>
    </div>
  </React.Fragment>
}

export default VidPending;