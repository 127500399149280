// these are the main steps of the customer flow
// each main step can contain its' own granular steps
// e.g. the SIGNATURES step encompasses the actions related to both e-signature and ink signature
const menuSteps = {
  VID: 1,
  SIGNATURES: 2,
  FINISH: 3,
};

export default menuSteps;

