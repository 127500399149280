import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import info from 'assets/images/info.svg';
import welcomeImage from 'assets/images/map.svg';
import { generateCode, verifyCode } from "helpers/backendHelper";
import { useNavigate } from "react-router-dom";
import { route } from "helpers/routeHelper";
import { useAuth } from "context/auth";
import logoSrc from 'assets/images/mavsign-logo-white.svg';
import { showSuccess, showWarning } from "helpers/utilHelper";
import { useNavigator } from "context/navigator";
import { isUserVerifiedLocal, isVerifCodeSent, saveVerifCodeSent, saveVerificationToken } from "helpers/tokenHelper";
import { useSelector } from "react-redux";
import classnames from "classnames";
import config from "config";

const DesktopContent = () => {

  const { user: authUser, refreshAuthUser } = useAuth();

  // router hook that helps redirect
  const navigate = useNavigate();

  // don't allow the user to navigate until the routes are initialized
  const { navIsReady } = useNavigator();

  /**** STATE ****/

  const [userVerified, setUserVerified] = useState(false);
  const [verifyError, setVerifyError] = useState(false);

  const [resendCodeTimer, setResetCodeTimer] = useState(null);
  const [isCodeResendInProgress, setIsCodeResendInProgress] = useState(false);

  // get redux state from the store
  const directRoute = useSelector(state => state.Layout.directRoute);

  /**** EFFECTS ****/

  // runs once on component mount
  useEffect(() => {
    if (isUserVerifiedLocal()) {
      // if user is authenticated, go to home page
      navigate(route('home'));
    } else if (authUser && !isUserVerifiedLocal() && !isVerifCodeSent()) {
      // generate code if user is auth, but is not beign currently verified
      triggerCodeGenerate(false);
    }
    pasteEventListener();
  }, []);

  // runs whenever the resendCodeTimer flag changes
  useEffect(() => {
    if (resendCodeTimer == null) {
      setResetCodeTimer(config.SECURITY_CODE_RESEND_DELAY);
    }
    let timer;
    if (resendCodeTimer > 0) {
      timer = setTimeout(() => setResetCodeTimer(resendCodeTimer - 1), 1000);
    }
    if (resendCodeTimer == 0) {
      setResetCodeTimer(-1);
    }
    return () => {
      clearTimeout(timer);
    }
  }, [resendCodeTimer]);

  const inputChanged = (event) => {
    setVerifyError(false);
    const code = event.target.value;

    // Check if the input is exactly 4 digits long and contains only numbers
    if (/^\d{4}$/.test(code)) {
      verifyCode(code).then(res => {
        if (!res.token) {
          setVerifyError(true);
        } else {
          saveVerificationToken(res.token);
          setUserVerified(true);
          // refresh auth user
          refreshAuthUser();
        }
      }).catch(err => {
        setVerifyError(true);
      });
    }
  }

  /**** UTILS ****/

  const doResendVerifCode = () => {
    triggerCodeGenerate(true);
    clearCode();
    setVerifyError(false);
  }

  const clearCode = () => {
    $('.code-digit').val('');
  }

  const pasteEventListener = () => {
    $('.code-digit').on('paste', (e) => {
      e.preventDefault();
      var code = e.originalEvent.clipboardData.getData('text').trim(0, 4);
      $('.code-digit').val(code);
      inputChanged(e);
    })
  }

  const triggerCodeGenerate = isResend => {
    setIsCodeResendInProgress(true);
    generateCode(isResend).then(res => {
      showSuccess("The verification code has been sent to your email address and phone number");
      // Save session variable in order to know a verification code was sent
      saveVerifCodeSent();
      setResetCodeTimer(config.SECURITY_CODE_RESEND_DELAY);
    }).catch(err => {
      showWarning("Unable to send the verification code");
    }).finally(() => {
      setIsCodeResendInProgress(false);
    });
  }

  const handleInput = (event) => {
    // Remove non-digit characters
    const value = event.target.value.replace(/\D/g, '');
    if (value.length > 4) {
      // Ensure the value doesn't exceed 4 digits
      event.target.value = value.slice(0, 4);
    } else {
      event.target.value = value;
    }
  }

  const isTimerRunning = () => !!resendCodeTimer && resendCodeTimer > 0;

  const canResendCode = () => !isCodeResendInProgress && !isTimerRunning();

  return <React.Fragment>
    <div id="mobileMenu" className="desktop">
      <Col md={12} className="text-center">
        <div id="topMenuWrapper">
          <div id="header">
            <div className="header-logo position-absolute">
              <img src={logoSrc} />
            </div>
            <div className="header-title">
              <span>Welcome</span>
            </div>
          </div>
        </div>
      </Col>
    </div>
    <div id="welcome_wrapper" className="account-pages desktop">
      <Container>
        <Row className="justify-content-center">
          <div className="top-body text-center">
            <img src={welcomeImage} className="welcome-image" />
          </div>
          <div className="welcome-column">
            <Card className="overflow-hidden welcome-card">
              <CardBody className="welcome-desktop-body">
                <div className="p-2 mb-3">
                  <div className="welcome-title text-center">Welcome to Mavsign!</div>
                </div>
                <p className="text-center welcom-paragraph">Please check your <b>phone messages</b> or<b> email</b> for the<b> 4-character validation code</b> to proceed.</p>
                <div id="codeWrapper" className={(verifyError ? 'invalid' : '') + (userVerified ? 'disabled' : '')}>
                  <input
                    onChange={inputChanged}
                    onInput={handleInput}
                    type="text"
                    inputMode="numeric"
                    className="code-digit"
                    maxLength="4"
                    pattern="\d{4}"
                    placeholder="- - - -"
                  />
                  <div className="code_check_result_icon">
                    {userVerified && <i className="fas fa-check"></i>}
                  </div>
                </div>
                {verifyError && <div id="invalidCodeLabel">Code is invalid</div>}
                <div className="resend-section-parent text-center d-flex justify-content-center align-items-center py-4">
                  <span>Didn’t recieve a code?</span>
                  <button className="resend-btn btn" onClick={doResendVerifCode} disabled={!canResendCode()}>
                    {/* <i className={classnames('mdi mdi-refresh me-1', { 'mdi-spin': isCodeResendInProgress })} /> */}
                    Click to resend {isTimerRunning() && `in ${resendCodeTimer} seconds`}
                  </button>
                </div>
                <div className="text-center mb-4">
                  <button onClick={() => navigate(directRoute ?? route('home'))} className={"btn btn-primary start-btn " + (!userVerified || !navIsReady() ? 'disabled' : '')}>Start</button>
                </div>
                <div className="d-flex justify-content-center mt-4">
                  <div className="welcome-infos p-2 d-flex align-items-center justify-content-center">
                    <img src={info} />
                    <span className="mr-2 info-text">Instructions will be provided on the following screens. You may be asked to <b>verify your identity, electronically sign documents, or provide a wet signature.</b></span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  </React.Fragment>
}

export default DesktopContent;