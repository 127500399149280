import React, { useState } from "react";
import iconSrc from 'assets/images/appointment/declined-icon.svg';
import { useAppointment } from "context/appointment";
import { Button, Card, CardBody } from "reactstrap";
import DesktopChatModal from "components/Shared/DesktopChatModal";
import Message from "model/message";
import { useMessageChannel } from "hooks/messageChannel";
import { useLocation } from "react-router-dom";
import { route } from "helpers/routeHelper";
import DesktopContactDirectModal from "components/Shared/ContactDirectModal/Desktop";
import NotaryInfo from "../../../components/Shared/NotaryInfo";

const AppointmentDeclined = () => {

  /********** STATE **********/

  const routeChannel = useMessageChannel();
  const [channel, setChannel] = useState(routeChannel);

  const { pathname } = useLocation();
  const shouldOpenChat = !!channel && pathname !== route("messages");
  const { order } = useAppointment();

  const [chatModal, showChatModal] = useState(shouldOpenChat);
  const [contactModal, showContactModal] = useState(false);

  const personData = { notaryFullName: order.orderNotary.notaryFullName, phone: order.orderNotary.notaryPhone, role: "notary" };

  /********** EVENT HANDLERS **********/

  const toggleContactModal = () => {
    showContactModal(current => !current);
  };

  const toggleChatModal = () => {
    showChatModal(current => !current);
  };

  const openChatModal = channel => {
    setChannel(channel)
    showContactModal(false);
    showChatModal(true);
  };

  return <React.Fragment>
    <Card className="limited-desktop-box">
      <CardBody>
        <div className="text-center pb-4 appointment-result">
          <img className="mt-4 mb-2" src={iconSrc}></img>
          <h5 className="mt-4 mb-5">Appointment declined</h5>
          <NotaryInfo notaryInfo={order.orderNotary} />
        </div>
        <div className="mt-2 mb-1 d-flex justify-content-center px-4">
          <Button color="primary" className="contact-btn" onClick={toggleContactModal}>Contact the notary</Button>
        </div>
      </CardBody>
    </Card>
    <DesktopContactDirectModal order={order} open={contactModal} onToggle={toggleContactModal} onOpenChat={openChatModal} selectedPerson={personData} />
    <DesktopChatModal open={chatModal} onToggle={toggleChatModal} channel={Message.CHANNEL_NOTARY_CUSTOMER} />
  </React.Fragment>
}

export default AppointmentDeclined;
