import React from "react";
import PropTypes from "prop-types";
import { Col } from "reactstrap";
import QRCode from "react-qr-code";
import info from 'assets/images/info.svg';
import idAvailable from 'assets/images/id-available.svg';
import wifi from 'assets/images/wifi.svg';
import light from 'assets/images/light.svg';

const QrCode = (props) => {

  return <React.Fragment>
    <div id="qr_parent">
      <h5 className="qr-description">Scan <strong>QR Code</strong> with your mobile device to complete de <strong>ID Verification</strong></h5>
      <div className="qr-wrapper">
        <QRCode value={props.qrSrc} size={170} />
      </div>
      <Col md={10} sm={10} className="m-auto pt-4">
        <div className="d-flex fade show alert-box">
          <img src={info} className="mx-2" />
          <span className="my-2 sure-text">Temporary IDs or paper IDs are not valid</span>
        </div>
        <div className="mb-4 mt-4 alert-text text-center">Make sure:</div>
        <div className="mb-3 alert-box-sure">
          <img src={idAvailable} className="mx-2" />
          <span className="text">You have your ID available</span>
        </div>
        <div className="mb-3 alert-box-sure">
          <img src={wifi} className="mx-2" />
          <span className="text">You have good internet connection</span>
        </div>
        <div className="mb-3 alert-box-sure">
          <img src={light} className="mx-2" />
          <span className="text">You are in a well-lit space</span>
        </div>
      </Col>
    </div>
  </React.Fragment>
}

QrCode.propTypes = {
  qrSrc: PropTypes.string,
};

export default QrCode;