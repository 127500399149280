import React from "react";
import PropTypes from "prop-types";
import retry from 'assets/images/retry-capture.svg';

const CaptureTypeDialog = ({ retryAutocapture, switchToManual, exitDialog }) => {

  CaptureTypeDialog.propTypes = {
    retryAutocapture: PropTypes.func,
    switchToManual: PropTypes.func,
    exitDialog: PropTypes.func
  };

  return <React.Fragment>
    <div className="capture-type-dialog-wrapper">
      <button id="closeDialogBtn" onClick={exitDialog}>
        <i className="fas fa-times"></i>
      </button>
      <div className="capture-type-dialog-content">
        <div className="justify-content-center d-flex">
          <img src={retry} className="mx-2" />
        </div>
        <div className="mt-3 mb-3 info text-center"><strong>Autocapture failed to produce a viable image.</strong></div>
        <div className="mb-3 info text-center">
          Do you want to:
        </div>
        <div className="mt-4 px-4">
          <button className="btn btn-primary submit-btn-mobile w-100 mb-2" onClick={retryAutocapture}>Retry autocapture</button>
          <button className="btn btn-primary submit-btn-mobile w-100" onClick={switchToManual}>Switch to manual capture</button>
        </div>
      </div>
    </div>
  </React.Fragment>
}

export default CaptureTypeDialog;
