import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import MobileMenu from "components/Shared/MobileMenu";
import { Link } from "react-router-dom";
import { route } from "helpers/routeHelper";
import { getOrder } from "helpers/backendHelper";
import NotFound from "pages/Error/NotFound";
import { useAuth } from "context/auth";
import OrderSigner from "model/orderSigner";
import menuStepsMobile from "constants/menuStepsMobile";
import imageInksign from 'assets/images/ink-sign-image-mobile.png';
import imageEsign from 'assets/images/e-sign-image-mobile.png';
import steps from 'assets/images/steps.svg';
import wifi from 'assets/images/wifi.svg';
import print from 'assets/images/print.svg';
import light from 'assets/images/light.svg';
import phone from 'assets/images/ink/phone-icon.svg';
import calendar from 'assets/images/ink/calendar-icon.svg';
import assignment from 'assets/images/ink/assignment-icon.svg';
import file from 'assets/images/ink/file-icon.svg';

const MobileContent = () => {

  /********** STATE **********/
  const [order, setOrder] = useState(null);
  const [orderError, setOrderError] = useState(false);

  const { user: authUser } = useAuth();

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    getOrder().then(res => {
      const order = res.order;
      if (!order || (!order.isInkSignRequired && !order.isEsignRequired) || authUser.type == OrderSigner.TYPE_DEALER) {
        setOrderError(true);
        return;
      }
      setOrder(order);
    }).catch(err => {
      setOrderError(true);
    });
  }, []);

  /********** OTHER **********/
  const inkSignInstructionsWithNotary = () => {
    return <React.Fragment>
      <div className="mb-3 sign-req">
        <img src={phone} className="mx-1" />
        Wait to be contacted by the notary.
      </div>
      <div className="mb-3 d-flex sign-req">
        <img src={calendar} className="mx-1" />
        Establish together a date and time for the signing.
      </div>
      <div className="mb-3 d-flex sign-req">
        <img src={assignment} className="mx-1" />
        Meet at the set time and date to sing the papers.
      </div>
      <div className="mb-1 d-flex sign-req">
        <img src={file} className="mx-1" />
        Provide the signatures and forms of ID requested.
      </div>
    </React.Fragment>
  }

  const inkSignInstructionsWithoutNotary = () => {
    return <React.Fragment>
      <div className="mb-3 sign-req">
        <img src={print} className="mx-1" />
        You can print all contracts
      </div>
      <div className="mb-3 d-flex sign-req">
        <img src={steps} className="mx-1" />
        <div>You have all the contracts ready and signed</div>
      </div>
      <div className="mb-3 sign-req">
        <img src={light} className="mx-1" />
        You are in a well-lit space
      </div>
      <div className="mb-3 sign-req">
        <img src={wifi} className="mx-1" />
        You have good internet connection
      </div>
    </React.Fragment>
  }

  return <React.Fragment>
    {order && <>
      <MobileMenu activeStep={menuStepsMobile.SIGNATURES} itemWidth="50%" />
      <div id="signatures" className="account-pages my-3">
        <Container>
          {!!order.isInkSignRequired &&
            <div className="inksign-header">
              <img className="signature-image-banner" src={imageInksign} />
            </div>
          }
          {!!order.isEsignRequired && !order.isInkSignRequired &&
            <div className="esign-header">
              <img className="signature-image-banner" src={imageEsign} />
            </div>
          }
          <Row className="justify-content-center">
            <Col md={9} lg={6} xl={5} className="sig-column">
              <Card className="overflow-hidden">
                <CardBody className="pt-3">
                  <div>
                    <h5 className="signatures-title">Continue with <strong>your signature.</strong> </h5>
                    {(!!order.isEsignRequired && !!order.isInkSignRequired) &&
                      <h6 className="signatures-subtitle">You can choose to start with <strong>electronic signature process </strong> or with the <strong>ink paper signatures</strong> process.</h6>
                    }
                  </div>
                  {!!order.isEsignRequired && <>
                    <div className="mb-3 mt-4 signatures-cat">For <strong>E-Signature</strong> make sure:</div>
                    <div className="mb-3 d-flex sign-req">
                      <img src={steps} className="mx-1" />
                      <div>That all steps and signatures are completed</div>
                    </div>
                    <div className="mb-3 sign-req">
                      <img src={wifi} className="mx-1" />
                      You have good internet connection
                    </div>
                  </>}
                  {!!order.isInkSignRequired && <>
                    <div className="mb-3 mt-4 signatures-cat">For <strong>Ink Paper signature</strong> make sure:</div>
                    {order.isNotaryRequired ? inkSignInstructionsWithNotary() : inkSignInstructionsWithoutNotary()}
                  </>
                  }
                </CardBody>
              </Card>
              {order.dealerInstructions &&
                <Card>
                  <CardBody>
                    <div className="mb-3 mt-0 signatures-cat">Dealer special instructions:</div>
                    <div className="mb-3 d-flex sign-req">
                      <img src={steps} className="mx-1" />
                      <div>{order.dealerInstructions}</div>
                    </div>
                  </CardBody>
                </Card>}
              <div>
                <Row className="mt-4 mb-4">
                  {!!order.isEsignRequired &&
                    <Col>
                      <Link to={route("e_signature")} className="btn btn-primary w-100">{(!!order.isEsignRequired && !!order.isInkSignRequired) ? 'Start E-sign' : 'Continue'}</Link>
                    </Col>}
                  {!!order.isInkSignRequired &&
                    <Col>
                      <Link to={order.isNotaryRequired ? route("appointment") : route("ink_signature")} className="btn btn-primary w-100" id="inkBtn">{(!!order.isEsignRequired && !!order.isInkSignRequired) ? 'Start Ink-sign' : 'Continue'}</Link>
                    </Col>}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
    }
    {orderError && <NotFound />}
  </React.Fragment>
}

export default MobileContent;