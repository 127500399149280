import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, CardBody, Card, Container, Input, Label } from "reactstrap";
import vidSrc from "assets/images/vid1.png";
import info from 'assets/images/info.svg';
import id from 'assets/images/ID.svg';
import wifi from 'assets/images/wifi.svg';
import light from 'assets/images/light.svg';


const Instructions = ({ next, consent, accepted, customerName }) => {

  const [termsAccepted, setTermsAccepted] = useState(accepted);
  const [name, setName] = useState(customerName);

  const handleTermsChange = (event) => {
    setTermsAccepted(event.target.checked)
  }

  const handleNameChange = (event) => {
    setName(event.target.value);
  }

  return <React.Fragment>
    <div id="id_instructions" className="account-pages">
      <Container>
        <div className="header-image-box">
          <img className="header-image" src={vidSrc} />
        </div>
        <Row className="justify-content-center">
          <Col md={9} lg={6} xl={5} className="vid-column">
            <Card className="overflow-hidden">
              <CardBody className="pt-3">
                <div className="p-2 mb-2">
                  <h5 className="confirm-title text-center">Scan your driver license/passport and take a selfie to confirm your identity</h5>
                </div>
                <div className="d-flex fade show alert-box">
                  <img src={info} className="mx-2" />
                  <span className="my-2 sure-text">Temporary IDs or paper IDs are not valid</span>
                </div>
                <div className="mb-3 mt-4 alert-text">Make sure:</div>
                <div className="mb-3 alert-box-sure d-flex">
                  <img src={id} className="mx-2" />
                  <span className="text">You have your Driver License/Passport</span>
                </div>
                <div className="mb-3 alert-box-sure d-flex">
                  <img src={id} className="mx-2" />
                  <span className="text">For Passport Cards go to Driver License tab</span>
                </div>
                <div className="mb-3 alert-box-sure d-flex">
                  <img src={wifi} className="mx-2" />
                  <span className="text">You have good internet connection</span>
                </div>
                <div className="mb-3 alert-box-sure d-flex">
                  <img src={light} className="mx-2" />
                  <span className="text">You are in a well-lit space</span>
                </div>
              </CardBody>
            </Card>
            <Card className="overflow-hidden mt-2">
              <CardBody>
                <Input type="text" className="form-control consent-signer-name" placeholder="First and Last name" onChange={handleNameChange} value={name} />
                <div className="consent-wrapper">
                  <div className="form-check form-switch form-switch-lg">
                    <Input
                      type="checkbox"
                      name="terms-consent"
                      onChange={handleTermsChange}
                      id="termsConsentCheck"
                      className="form-check-input-lg me-2"
                      defaultChecked={accepted}
                    />
                    <Label className="form-check-label" htmlFor="terms-consent" />
                  </div>
                  <button id="consentClick" className="ms-0 pt-1" onClick={() => consent(termsAccepted, name)}>Consent to <u>the use and release of my digital photograph</u></button>
                </div>
              </CardBody>
            </Card>
            <button to="" className={`btn btn-primary w-100 start-btn-mobile ${!termsAccepted || !name.trim() ? "disabled" : ""}`} onClick={() => next(name, termsAccepted)}>Start ID verification</button>
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
}

Instructions.propTypes = {
  next: PropTypes.func,
  consent: PropTypes.func,
  accepted: PropTypes.bool,
  customerName: PropTypes.string
};

export default Instructions;