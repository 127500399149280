import React from "react";

const IDBackInfo = () => {

  return <div className="instructions-wrapper px-2">
    <div className="mb-2 instructions-label">Instructions:</div>
    <div>
      <i className="fas fa-check me-3 fa-lg instr-i"></i>
      Look straight into the camera
    </div>
    <div>
      <i className="fas fa-check me-3 fa-lg instr-i"></i>
      Center your face in the oval
    </div>
    <div>
      <i className="fas fa-check me-3 fa-lg instr-i"></i>
      Avoid a busy or lively background
    </div>
  </div>
}

export default IDBackInfo;
