import Preloader from "components/Shared/Preloader";
import { getOrder } from "helpers/backendHelper";
import NotFound from "pages/Error/NotFound";
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { useAuth } from "context/auth";
import OrderSigner from "model/orderSigner";
import Contracts from "./Steps/Contracts";
import DesktopMenu from "components/Shared/DesktopMenu";
import Footer from "components/Shared/Footer";
import { useNavigate } from "react-router-dom";
import { route } from "helpers/routeHelper";
import menuSteps from "constants/menuSteps";

const STEP_CONTRACTS = 0;

const DesktopContent = () => {

  const { user: authUser } = useAuth();
  // router hook that helps redirect
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(STEP_CONTRACTS);
  const [isBusy, setIsBusy] = useState(false);
  const [order, setOrder] = useState(null);
  const [orderError, setOrderError] = useState(false);

  // runs once on component mount
  useEffect(() => {
    setIsBusy(true);
    getOrder().then(res => {
      setIsBusy(false);
      // if the order has the notary service
      // redirect the user to the appointment screen
      // this is because the ink-signatures are managed by the notary in that case
      if (res.order && res.order.isNotaryRequired) {
        navigate(route('appointment'));
        return;
      }
      // there are some cases in which we should display an error if the users lands on this page:
      // - if we couldn't load the order
      // - if the order doesn't have the ink-sign service
      // - if the user is a dealer signer (they can only navigate in the e-sign screens)
      if (!res.order || !res.order.isInkSignRequired || authUser.type == OrderSigner.TYPE_DEALER) {
        setOrderError(true);
        return;
      }
      setOrder(res.order)
    }).catch(err => {
      setOrderError(true);
    })
  }, []);

  // if we are on the first step of ink signature (signing the contract)
  // it means that on back, we should redirect the user to the signatures page
  // otherwise, go back to previous step
  const decreaseStep = () => {
    if (currentStep - 1 < STEP_CONTRACTS) {
      navigate(route('signatures'));
    } else {
      setCurrentStep(currentStep - 1);
    }
  }

  // list of steps in the e-sign process
  // each step contains:
  // - component -> the component to render
  // - options -> props sent to that component
  const steps = [
    {
      component: Contracts,
      options: {
        hasESign: order ? order.isEsignRequired : null,
        goBack: decreaseStep,
        order: order,
      }
    }
  ];

  const ActiveStep = steps[currentStep].component;

  return <React.Fragment>
    <div id="signature_desktop_wrapper" className="vh-80">
      {order && <>
        <DesktopMenu title={"Contracts to be signed"} activeStep={menuSteps.INKSIGNATURES} />
        <Container>
          <Row className="justify-content-center h-100">
            <Col md={12} className="p-0">
              <ActiveStep {...steps[currentStep].options} />
            </Col>
          </Row>
        </Container>
        <Footer />
      </>
      }
      {isBusy && <Preloader />}
      {orderError && <NotFound />}
    </div>
  </React.Fragment>
}

export default DesktopContent;
