import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { route } from "helpers/routeHelper";
import { getOrder } from "helpers/backendHelper";
import NotFound from "pages/Error/NotFound";
import { useAuth } from "context/auth";
import OrderSigner from "model/orderSigner";
import DesktopMenu from "components/Shared/DesktopMenu";
import Footer from "components/Shared/Footer";
import menuSteps from "constants/menuSteps";
import imageInksign from 'assets/images/ink-sign-image.png';
import imageEsign from 'assets/images/e-sign-image.png';
import steps from 'assets/images/steps.svg';
import wifi from 'assets/images/wifi.svg';
import print from 'assets/images/print.svg';
import light from 'assets/images/light.svg';
import phone from 'assets/images/ink/phone-icon.svg';
import calendar from 'assets/images/ink/calendar-icon.svg';
import assignment from 'assets/images/ink/assignment-icon.svg';
import file from 'assets/images/ink/file-icon.svg';

const DesktopContent = () => {

  /********** STATE **********/
  const [order, setOrder] = useState(null);
  const [orderError, setOrderError] = useState(false);

  const { user: authUser } = useAuth();

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    getOrder().then(res => {
      const order = res.order;
      if (!order || (!order.isInkSignRequired && !order.isEsignRequired) || authUser.type == OrderSigner.TYPE_DEALER) {
        setOrderError(true);
        return;
      }
      setOrder(order);
    }).catch(err => {
      setOrderError(true);
    });
  }, []);

  /********** OTHER **********/
  const inkSignInstructionsWithNotary = () => {
    return <React.Fragment>
      <div className="mb-3 sign-req">
        <img src={phone} className="mx-1" />
        Wait to be contacted by the notary.
      </div>
      <div className="mb-3 d-flex sign-req">
        <img src={calendar} className="mx-1" />
        Establish together a date and time for the signing.
      </div>
      <div className="mb-3 d-flex sign-req">
        <img src={assignment} className="mx-1" />
        Meet at the set time and date to sing the papers.
      </div>
      <div className="mb-1 d-flex sign-req">
        <img src={file} className="mx-1" />
        Provide the signatures and forms of ID requested.
      </div>
    </React.Fragment>
  }

  const inkSignInstructionsWithoutNotary = () => {
    return <React.Fragment>
      <div className="mb-3 sign-req">
        <img src={print} className="mx-1" />
        You can print all contracts
      </div>
      <div className="mb-3 d-flex sign-req">
        <img src={steps} className="mx-1" />
        <div>You have all the contracts ready and signed</div>
      </div>
      <div className="mb-3 sign-req">
        <img src={light} className="mx-1" />
        You are in a well-lit space
      </div>
      <div className="mb-3 sign-req">
        <img src={wifi} className="mx-1" />
        You have good internet connection
      </div>
    </React.Fragment>
  }

  return <React.Fragment>
    {order && <>
      <DesktopMenu title={"Contracts to be signed"} activeStep={!!order.isEsignRequired ? menuSteps.ESIGNATURES : menuSteps.INKSIGNATURES} />
      <div id="signatures" className="account-pages desktop mb-4">
        <Container>
          <Row className="justify-content-center">
            <Col md={9} lg={6} xl={5} className="sig-column">
              <Card className="overflow-hidden sig-card">
                {!!order.isInkSignRequired &&
                  <div className="inksign-header">
                    <img className="signature-image-banner" src={imageInksign} />
                  </div>
                }
                {!!order.isEsignRequired && !order.isInkSignRequired &&
                  <div className="esign-header">
                    <img className="signature-image-banner" src={imageEsign} />
                  </div>
                }
                <CardBody className="sig-desktop-body">
                  <div>
                    <h5 className="signatures-title text-center">Continue with <strong>your signature.</strong></h5>
                  </div>
                  <div className="instr-parent">
                    {!!order.isEsignRequired &&
                      <div>
                        <div className="signatures-cat text-center">For <strong>E-Signature</strong> make sure:</div>
                        <div className="mb-3 d-flex sign-req">
                          <img src={steps} className="mx-1" />
                          <div>That all steps and signatures are completed</div>
                        </div>
                        <div className="mb-3 sign-req">
                          <img src={wifi} className="mx-1" />
                          You have good internet connection
                        </div>
                      </div>
                    }
                    <div>
                      {!!order.isInkSignRequired && <>
                        <div className="signatures-cat text-center">For <strong>Ink Paper signature</strong> make sure:</div>
                        {order.isNotaryRequired ? inkSignInstructionsWithNotary() : inkSignInstructionsWithoutNotary()}
                      </>}
                      {order.dealerInstructions && <>
                        <div className="signatures-cat">Dealer special instructions:</div>
                        <div className="mb-3 d-flex sign-req">
                          <img src={steps} className="mx-1" />
                          <div>{order.dealerInstructions}</div>
                        </div>
                      </>}
                    </div>
                  </div>
                  <Row className="btns-row">
                    {!!order.isEsignRequired &&
                      <Col className="justify-content-center d-flex">
                        <Link to={route("e_signature")} className="btn btn-primary start-signature-btn">{(!!order.isEsignRequired && !!order.isInkSignRequired) ? 'Start E-sign' : 'Continue'}</Link>
                      </Col>
                    }
                    {!!order.isInkSignRequired &&
                      <Col className="justify-content-center d-flex">
                        <Link to={order.isNotaryRequired ? route("appointment") : route("ink_signature")} className="btn btn-primary start-signature-btn" id="inkBtn">{(!!order.isEsignRequired && !!order.isInkSignRequired) ? 'Start Ink-sign' : 'Continue'}</Link>
                      </Col>
                    }
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    </>
    }
    {orderError && <NotFound />}
  </React.Fragment>
}

export default DesktopContent;