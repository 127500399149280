import React, { useEffect, useState } from "react";
import MobileMenu from "components/Shared/MobileMenu";
import { Row, Col, Container } from "reactstrap";
import DocumentItem from "./Partial/DocumentItem";
import NotFound from "pages/Error/NotFound";
import Preloader from "components/Shared/Preloader";
import { Link, useNavigate } from "react-router-dom";
import { getOrderFinancialContracts } from "helpers/backendHelper";
import { useNavigator } from "context/navigator";
import menuStepsMobile from "constants/menuStepsMobile";

const MobileContent = () => {

  // router hook that helps redirect
  const navigate = useNavigate();

  // navigator context that helps determine the next available route
  const { getNextRoute } = useNavigator();

  /********** STATE **********/

  const [isBusy, setIsBusy] = useState(true);
  const [documents, setDocuments] = useState(null);
  const [documentsError, setDocumentsError] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // fetch financial documents
    getOrderFinancialContracts().then(res => {
      setIsBusy(false);

      // error fetching documents
      if (res.message) {
        setDocumentsError(true);
        return;
      }

      // go straight to the next step if there are no docs
      if (!res.docs.length) {
        return navigate(getNextRoute())
      }

      // if we have docs
      setDocuments(res.docs);
    }).catch(err => {
      setIsBusy(false);
      setDocumentsError(true);
    })
  }, []);

  return <React.Fragment>
    <div id="finance_mobile_wrapper">
      {/** Documents Preview is done before any signing process begins, therefore we consider the active step (last completed step) to be the previous step (VID) */}
      <MobileMenu activeStep={menuStepsMobile.SIGNATURES} itemWidth="50%" />
      <Container>
        <Row className="justify-content-center">
          <Col md={9} lg={6} xl={5} className="p-0">
            <div className="documents-actions-row">
              <div className="doc-title">Preview Documents</div>
            </div>
            <React.Fragment>
              <div id="documents_card">
                {documents && <div className="accordion" id="accordion">
                  {documents.map((document, index) => (
                    <DocumentItem document={document} key={document.id} index={index} />
                  ))}
                </div>}
                {documentsError && <NotFound />}
                <div className="continue-btn-wrapper">
                  <Row className="justify-content-center">
                    <Col md={9} lg={6} xl={5}>
                      <Link className="btn btn-primary w-100" to={getNextRoute()}>Continue</Link>
                    </Col>
                  </Row>
                </div>
              </div>
            </React.Fragment>
          </Col>
        </Row>
      </Container>
    </div>
    {isBusy && <Preloader />}
  </React.Fragment>
}

export default MobileContent;