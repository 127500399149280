import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import OrderDoc from 'model/orderDoc';
import { useSubscribeToOrderDoc } from 'hooks/socket';
import { getBeUrl, showError } from 'helpers/utilHelper';
import { addAccessToken } from 'helpers/tokenHelper';
import { formats, formatTimestamp, timestamp } from 'helpers/dateHelper';

const ContractPages = ({ contract, captures, upload }) => {

  // start receiving updates about this particular order doc
  useSubscribeToOrderDoc(contract.id)

  const [selectedPage, setSelectedPage] = useState(null);

  // runs whenever `selectedPage` changes
  useEffect(() => {
    if (selectedPage) {
      openFileModal();
    }
  }, [selectedPage]);

  // contract page list, inside accordion
  const getContractPages = () => {
    let content = [];
    for (let i = 1; i <= contract.numOfPages; i++) {
      let captureSrc = null;
      let pageStatus = null;
      // check if has local upload
      if (captures[contract.id] && captures[contract.id][i]) {
        captureSrc = captures[contract.id][i];
        pageStatus = OrderDoc.PAGE_STATUS_PENDING_REVIEW;
      } else if (contract.pages && contract.pages[i] != null) {
        // check if has api upload
        captureSrc = getBeUrl(addAccessToken(`order-doc/${contract.id}/ink-sign/page/${i}.png?${timestamp()}`));
        pageStatus = contract.pages[i].status;
      }
      let page =
        <div className="contract-page" key={i}>
          <div className="contract-page-title">Page contract {i}</div>
          <div id="contract-page-capture" className={captureSrc ? 'has-capture' : ''}>
            <img src={captureSrc}></img>
            {pageStatus == OrderDoc.PAGE_STATUS_REJECTED && <button id="rejectedBadge" className="btn bg-danger text-light unclickable">Rejected</button>}
          </div>
          {!captureSrc && <>
            <button className="btn btn-success page-status-btn" onClick={() => scan(i)}>
              Scan page
              <i className="mdi mdi-credit-card-scan-outline"></i>
            </button>
          </>
          }
          {captureSrc && pageStatus == OrderDoc.PAGE_STATUS_PENDING_REVIEW && <>
            <button className="btn bg-warning text-light page-status-btn unclickable">
              Pending review
              <i className="bx bx-time-five"></i>
            </button>
          </>
          }
          {captureSrc && pageStatus !== OrderDoc.PAGE_STATUS_ACCEPTED && <>
            <button className="btn btn-danger text-light page-status-btn" onClick={() => scan(i)}>
              Scan page again
              <i className="bx bx-x-circle"></i>
            </button>
          </>
          }
          {captureSrc && pageStatus == OrderDoc.PAGE_STATUS_ACCEPTED && <>
            <button className="btn bg-success text-light page-status-btn unclickable">
              Accepted page
              <i className="bx bx-check-circle"></i>
            </button>
          </>
          }
        </div>;
      content.push(page);
    }
    return content;
  }

  const scan = (pageId) => {
    if (pageId == selectedPage) {
      // if current page is the selected one, manually open modal
      // useEffect will not be triggered
      openFileModal();
    }
    setSelectedPage(pageId);
  }

  const [lastFileSelected, setLastFileSelected] = useState('');

  const getFile = (event) => {
    // keep track of the file that was selected
    setLastFileSelected(event.target.value);

    const allowedFileTypes = ['image/jpeg', 'image/png'];

    // show an alert if the same file is selected and skip the upload
    if (lastFileSelected === event.target.value) {
      showError('Cannot select the same file more than once');
      return;
    }

    // Check if the file type is allowed
    if (!allowedFileTypes.includes(event.target.files[0].type)) {
      showError('Unknown format, please upload jpeg or png files');
      return;
    }

    // Upload the file if the type is allowed
    upload(event.target.files[0], selectedPage, contract.id)

    // reset the value of the input in order for `change` event to be triggered every time a file is selected
    // (by default, it's not triggered when the user selects the same file consecutively)
    event.target.value = '';
  }

  const openFileModal = () => {
    $('#uploadInput').trigger('click');
  }

  return <React.Fragment>
    {contract &&
      <div className="contract-pages-wrapper">
        <span className="contract-date-label">Contract date:</span>
        <span className="contract-date-value">{formatTimestamp(contract.createdTs, formats.CONTRACT_DATE)}</span>
        <hr className="contract-date-separator"></hr>
        {getContractPages()}
        <input id="uploadInput" type="file" className="d-none" onChange={getFile} accept="image/png, image/jpeg" />
      </div>
    }
  </React.Fragment>
};

ContractPages.propTypes = {
  contract: PropTypes.object,
  captures: PropTypes.array,
  upload: PropTypes.func,
};

export default ContractPages;